import * as React from 'react';
import { useState } from 'react';
import BootstrapNavbar from '../../components/navigationBar/mainMenu/navigationBar';
import DefaultHeaderMenu from '../../components/defaultHeaderMenu/defaultHeaderMenu';
import Footer from '../../components/footer/footer';
import TopBanner from '../../common/topBanner/topBanner';
import BreadcrumbsBlock from '../../common/breadcrumbsBlock/breadcrumbsBlock';
import LocationDefault from '../../pageSections/locations/locationDefault';
// import LocationPageTemplateRoute from './locationPageTemplateRoute';
import GlobalStyles from '../../styles/globalStyles';
import BackToTopScroll from '../../components/backToTopScroll/backToTopScroll';

const BreadcrumbsBlockData = {
  backgroundColor: '#ffc423',
  borderTopColor: '#e4ae1b',
  title: 'Locations',
};

const LocationPageTemplate = ({ data, location }) => {
  const path = location?.pathname?.split('/')[1];
  const [showMapLeftPanel, setShowMapLeftPanel] = useState(true);

const breadcrumbsTitle = data?.allContentfulFasGasPlus?.edges[0]?.node?.name;
  return (
    <div style={{ background: '#fff' }}>
      <GlobalStyles/>
      <DefaultHeaderMenu />
      <BootstrapNavbar activeLink={path} />
      <TopBanner path={location.pathname} />
      <BreadcrumbsBlock
        {...BreadcrumbsBlockData}
        path={location.pathname}
        breadcrumbsTitle={breadcrumbsTitle}
        isLocation={true}
        onClick={() => {
          setShowMapLeftPanel(!showMapLeftPanel);
        }}
      />
      <LocationDefault showMapLeftPanel={showMapLeftPanel} />
      <BackToTopScroll/>
      <Footer />
    </div>
  );
};

export default LocationPageTemplate;
