import { Link } from 'gatsby';
import styled from 'styled-components';

export const MainDiv = styled.div`
  z-index: 100;
  left: 244px;
  top: 122px;
  position: absolute;
  width: 100%;
  cursor: default;
  display: block;
  font: 400 11px Roboto, Arial, sans-serif;
  ::after {
    background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0) 51%,
        rgba(255, 255, 255, 0) 100%
      )
      #fff;
    box-shadow: -2px 2px 2px 0 rgb(178 178 178 / 40%);
    content: '';
    height: 15px;
    left: -215px;
    position: absolute;
    top: -150px;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 15px;
  }
`;
export const InnerDiv = styled.div`
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  transform: translate3d(-50%, -100%, 0);
  background-color: white;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 2px 7px 1px rgb(0 0 0 / 30%);
  font-weight: 300;
  font-size: 13px;
  padding-right: 0px;
  padding-bottom: 20px;
  max-width: 648px;
  max-height: 313px;
  min-width: 0px;
`;
export const DivStyle = styled.div`
  max-height: 295px;
  overflow: auto;
`;
export const PopupCloseStyle = styled.div`
  background: none;
  display: block;
  border: 0px;
  margin: 0px;
  padding: 0px;
  text-transform: none;
  appearance: none;
  position: absolute;
  cursor: pointer;
  user-select: none;
  top: -1px;
  right: -6px;
  width: 30px;
  height: 30px;
  :hover {
    opacity: 0.6;
  }
`;
export const InfoWindowStyle = styled.div`
  width: 420px;
  padding: 20px;
  font-family: 'Gotham-Book', arial, sans-serif;
  font-size: 20px;
  line-height: 20px;
  color: #666;
  height: auto;
`;
export const HeaderH2 = styled.h2`
  font-size: 24px;
  line-height: 24px;
  margin: 0 !important;
  color: #008268;
  padding: 0;
  font-family: 'Gotham-Light', arial, sans-serif;
  font-weight: normal !important;
`;
export const InfoWindowLeft = styled.div`
  float: left;
  width: 190px;
  font-size: 12px;
  margin: 0;
`;

export const HeaderH3 = styled.h3`
  font-family: 'Gotham-Medium', arial, sans-serif;
  font-size: 16px;
  line-height: 16px;
  margin: 0 0 5px 0;
  color: #666;
`;

export const PopupAnchor = styled.a`
  text-decoration: none;
  color: #eea200;
  :hover {
    text-decoration: none;
    color: #008268;
  }
`;
export const PopupAnchorLink = styled.a`
  text-decoration: none;
  color: #eea200;
  :hover {
    text-decoration: none;
    color: #008268;
  }
`;

export const InfoWindowRight = styled.div`
  float: left;
  width: 175px;
  font-size: 12px;
`;
