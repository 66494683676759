import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import * as Styled from '../../templates/locationTemplate/locationPageTemplateStyle';
import IconPinBranch from '../../images/icon-pin.png';
import BranchHoursImg from '../../images/icon-listing-247.png';
import DieselImg from '../../images/icon-listing-diesel.png';
import GoogleMapReact from 'google-map-react';
import { Row, Col, Navbar, Nav } from 'react-bootstrap';
import LocationPinMarker from '../../common/locationMarker/locationMarker';
import { LocationTypes } from '../../utils/utils';
import Hamburger from '../../images/icon-hamburger.png';
import { Helmet } from 'react-helmet';
import {
  addTrailingSlash,
  isExternalUrlhref,
  isSmallDevice,
} from '../../utils';

const locationCountries = ['ALL', 'AB', 'BC', 'MB', 'NB', 'ON', 'SK', 'YT'];
let markersArray = [];

const LocationDefault = () => {
  const [selectedProvince, setSelectedProvince] = useState(
    locationCountries[0]
  );
  const locationDataQuery = useStaticQuery(graphql`
    query locationDataMyQueryDefault {
      allContentfulLocation(
        filter: {
          node_locale: { eq: "en-US" }
          brand: { brand: { eq: "Fas Gas Plus" } }
        }
      ) {
        edges {
          node {
            address1
            address2
            node_locale
            branchHours
            branchManager
            cardlockHours
            email
            hasDef
            type {
              type
            }
            hasDiesel
            hasGasoline
            hasHeatingOil
            hasHeatingEquipment
            hasLubricants
            hasPropane
            latitude
            location
            longitude
            phone
            tollfree
            pipelineCardAccepted
            postalCode
            serviceArea {
              serviceArea
            }
            city
            province
            id
            spaceId
            useLatLongOnly
            urlSlug
            fax
          }
        }
      }
    }
  `);
  let phones;
  const [locationsData, setLocationsData] = useState(
    locationDataQuery?.allContentfulLocation?.edges
  );
  const [lat, setLat] = useState(parseFloat(locationsData[0]?.node?.latitude));
  const [lng, setLng] = useState(parseFloat(locationsData[0]?.node?.longitude));
  const [zoom, setZoom] = useState(5);
  const [map, setMap] = useState();
  const [maps, setMaps] = useState();
  const [placeholder, setPlaceholder] = useState();
  const [clickedLocation, setClickedLocation] = useState(false);
  const[mapsZoom, setMapszoom]=useState(null);
  let InfoWindowObject = null;
  const input = useRef(null);
  const inputTablet = useRef(null);

  const closeOtherInfo = () => {
    if (InfoWindowObject) {
      /* detach the info-window from the marker ... undocumented in the API docs */
      InfoWindowObject?.set('marker', null);
      /* and close it */
      InfoWindowObject?.close();
      /* blank the Object */
      InfoWindowObject = null;
    }
  };

  const getMap = (item) => {
    setLat(parseFloat(item?.latitude));
    setLng(parseFloat(item?.longitude));
    setClickedLocation(item);
    if (isSmallDevice()) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${item?.location},${item?.address1},${item?.city},${item?.province}, ${item?.postalCode}`,
        '_blank'
      );
    }
  };

  const getMarkerIcon = (types) => {
    let markerIcon = null;
    markerIcon = IconPinBranch;
    return markerIcon;
  };
  const searchByCityNameOrPostalCode = (searchText) => {
    let isPostalCode = false;
    const PostalCode = locationsData?.map((node) => {
      let post = '' + node?.node?.postalCode;
      if (post.trim().toLowerCase() === searchText.trim().toLowerCase())
        isPostalCode = true;
    });

    const searchResult = locationsData?.find(
      (item) =>
        item?.node?.city?.trim().toLowerCase() ===
          searchText.trim().toLowerCase() ||
        item?.node?.postalCode?.trim().toLowerCase() ===
          searchText.trim().toLowerCase() ||
        item?.node?.location?.trim().toLowerCase() ===
          searchText.trim().toLowerCase()
    );
    if (searchResult) {
      setLat(parseFloat(searchResult?.node?.latitude));
      setLng(parseFloat(searchResult?.node?.longitude));
      if (isPostalCode) setZoom(15);
      else setZoom(10);
    } else {
      var geocoder = new google.maps.Geocoder();
      geocoder?.geocode({ address: searchText }, function (results, status) {
        if (status === 'OK') {
          setLat(results[0]?.geometry?.location?.lat());
          setLng(results[0]?.geometry?.location?.lng());
          if (isPostalCode) setZoom(15);
          else setZoom(10);
        }
      });
    }
  };

  const insertDiesel = (hasDiesel) => {
    if (hasDiesel)
      return `
    <div class="feature">
    <img src=${DieselImg} alt="diesel"/>
    <span>Diesel</span>
    </div>
    `;
    else return `</>`;
  };

  const insert24Hrs = (branchHours) => {
    if (branchHours == '24 Hrs')
      return `
     <div class="feature">
    <img src=${BranchHoursImg} alt="24/7"/>
    <span>Open 24/7</span>
    </div>
     `;
    else return `</>`;
  };

  useEffect(() => {
    let result = locationDataQuery?.allContentfulLocation?.edges;

    if (selectedProvince != locationCountries[0]) {
      result = result?.filter(
        (data) => data?.node?.province == selectedProvince
      );
    }
    setLocationsData(result);
    setLat(parseFloat(result[0]?.node?.latitude));
    setLng(parseFloat(result[0]?.node?.longitude));
  }, [selectedProvince]);

  useEffect(() => {
    //delete all markers
    if (markersArray?.length >= 1) {
      markersArray?.forEach((marker) => {
        marker?.setMap(null);
      });
      markersArray = [];
    }

    if (maps && map && markersArray.length < 1) {
      locationsData?.map((location, index) => {
        if (location?.node?.address1) {
          const marker = new maps.Marker({
            position: new maps.LatLng(
              location?.node?.latitude ?? 0,
              location?.node?.longitude ?? 0
            ),
            icon: {
              url: getMarkerIcon(location?.node?.type), // url
              scaledSize: new google.maps.Size(53, 50), // size
            },
            map: map,
          });

          markersArray?.push(marker);
          const infoWindow = new maps.InfoWindow({
            content: `
                          <div class="infoWindow">
                              
                              <h2>${location?.node?.city} </h2>
                            
                              <div class="infoWindow-left">
                              ${
                                location?.node?.address2 !== null
                                  ? location?.node?.address2
                                  : ''
                              }
                                ${location?.node?.address1},
                            
                               
                                ${location?.node?.province} ${
              location?.node?.postalCode
            }  <br />
                             
                                
                                ${
                                  location?.node?.phone !== null
                                    ? location?.node?.phone
                                    : ''
                                }
                               <br/>
                               <br/>
                               
                               ${insert24Hrs(location?.node?.branchHours)}
                               ${insertDiesel(location?.node?.hasDiesel)}
                                  
                              
                              </div>
                            
                                
                          </div>`,
            ariaLabel: 'Marker',
          });
          if (
            clickedLocation &&
            clickedLocation.latitude === location.node.latitude &&
            clickedLocation.longitude === location.node.longitude
          ) {
            map.panTo(marker?.getPosition());
            closeOtherInfo();
            setZoom(14);
            InfoWindowObject = infoWindow;
            infoWindow?.open({
              anchor: marker,
              map,
            });
          }
          //marker.setMap(null);
          marker?.addListener('click', () => {
            closeOtherInfo();
            infoWindow?.open({
              anchor: marker,
              map,
            });
            map.panTo(marker?.getPosition());
            InfoWindowObject = infoWindow;
          });
        }
      });
    }
  }, [locationsData, map, maps, clickedLocation]);

  const handleApiLoaded = (map, maps) => {
    // const goog = new google.maps
    setMapszoom(maps.ControlPosition.RIGHT_TOP);
    const styles = [
      {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [{ color: '#98d0ff' }],
      },
    ];

    // eslint-disable-next-line no-undef
    const styledMap = new google.maps.StyledMapType(styles, {
      name: 'Styled Map',
    });
    map.mapTypes.set('map_style', styledMap);
    map.setMapTypeId('map_style');
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Find A Gas Station Near You | Fas Gas Plus</title>
        <meta name="description" content></meta>
      </Helmet>
      <Styled.LocationContainer id="locationMap">
        <Row className="g-0 d-none d-md-block">
          <Styled.LocationDataCol>
            <Styled.GoogleMapContainer>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.GATSBY_GOOGLE_MAP_APIKEY,
                  libraries: ['places', 'geometry'],
                }}
                center={{ lat, lng }}
                options={
                  ({ streetView: true, streetViewControl: true ,
                  
                    zoomControl: true,
                    zoomControlOptions: {
                      position: mapsZoom,
                    },
                  })
                }
                zoom={zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => {
                  setMap(map);
                  setMaps(maps);
                  handleApiLoaded(map, maps);
                }}
              />
              <Styled.MapLeftBarContainer>
                <Styled.MapSearch>
                  <div>
                    <Styled.MapSearchInput>
                      <Styled.MapSearchInputField
                        ref={input}
                        type="text"
                        placeholder="Search by City or Postal Code"
                        $placeholderColor={placeholder === 0 ? true : false}
                        onKeyUp={(event) => {
                          if (event?.key === 'Enter') {
                            setPlaceholder(event?.target?.value);
                            searchByCityNameOrPostalCode(event?.target?.value);
                            setClickedLocation(false);
                          }
                        }}
                      />
                    </Styled.MapSearchInput>
                    <Styled.MapSearchSubmit>
                      <Styled.MapSearchSubmitInput
                        type="submit"
                        value=""
                        onClick={() => {
                          searchByCityNameOrPostalCode(input?.current?.value);
                          setClickedLocation(false);
                        }}
                      />
                    </Styled.MapSearchSubmit>
                  </div>
                </Styled.MapSearch>
              </Styled.MapLeftBarContainer>
            </Styled.GoogleMapContainer>
          </Styled.LocationDataCol>
        </Row>
        <Row className="g-0" id="tabs">
          <Col xs={12} sm={12} md={12} lg style={{ marginTop: '45px' }}>
            <Styled.CountriesListContainer>
              {locationCountries?.map((item, index) => {
                return (
                  <Styled.CountriesListItem
                    key={index}
                    onClick={() => {
                      setSelectedProvince(item);
                      setZoom(5);
                      setClickedLocation(false);
                    }}
                    $selectedProvince={selectedProvince}
                    $currentProvince={item}
                  >
                    {item}
                  </Styled.CountriesListItem>
                );
              })}
            </Styled.CountriesListContainer>
          </Col>
        </Row>
        <Row style={{ paddingTop: '20px' }} className="g-0 " id="tabs">
          <Styled.TableSubContainer xs={12} sm={12} md={12} lg>
            <Styled.LocationTable striped borderless>
              <Styled.THead>
                <tr>
                  <th style={{ width: '25%' }}>LOCATION</th>
                  <th style={{ width: '35%' }}>ADDRESS</th>
                  <th style={{ width: '20%' }}>PHONE</th>
                  <th style={{ width: '10%' }}>24 HOUR</th>
                  <th style={{ width: '10%' }}>DIESEL</th>
                </tr>
              </Styled.THead>
              <Styled.TBody>
                {locationsData
                  ?.sort((a, b) => a.node.city.localeCompare(b.node.city))
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <Styled.Td>
                          <div className="d-flex flex-column ">
                            <Styled.LinkStyle
                              href="#locationMap"
                              onClick={() => getMap(item?.node)}
                            >
                              <Styled.AddressText>
                                <div>{item.node.city}</div>
                              </Styled.AddressText>
                            </Styled.LinkStyle>
                          </div>
                        </Styled.Td>
                        <Styled.Td>
                          <div>
                            {' '}
                            {item.node.address1 +
                              ', ' +
                              item.node.province +
                              ' ' +
                              item.node.postalCode}
                          </div>
                          <div> {item.node.address2}</div>
                        </Styled.Td>
                        <Styled.Td>
                          <div>{item.node.phone}</div>
                        </Styled.Td>
                        <Styled.Td>
                          <div>
                            {item.node?.branchHours === '24 Hrs' ? (
                              <Styled.ImageStyle24hours
                                src={BranchHoursImg}
                                alt="24/7"
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </Styled.Td>
                        <Styled.Td>
                          <div>
                            {item.node?.hasDiesel === true ? (
                              <Styled.ImageStyleDiesel
                                src={DieselImg}
                                alt="diesel"
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </Styled.Td>
                      </tr>
                    );
                  })}
              </Styled.TBody>
            </Styled.LocationTable>
          </Styled.TableSubContainer>
        </Row>
      </Styled.LocationContainer>
    </>
  );
};

export default LocationDefault;
